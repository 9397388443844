import { Card,  Tabs, Typography } from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../utils/i18nUtils";
import AuthorizedKeyCard from "./AuthorizedKeyCard";
import { useState } from "react";
import store from "../../../store/store";
import { isCustomKey, isICEKey, isMOBKey } from "./helper";

export interface Keys {
  reference: string,
  friendlyName?: string,
  issuedOn?: string,
  expiresOn?: string,
  endpointId?: number,
  ecpFrame?: string,
  legacyFrame?: any,
  configCardEnablement?: boolean
}


export enum renderAuthorizedKey {
  ALLKEYS = "allKeys",
  ICEKEYS = "iceKeys",
  MOBKEYS = "mobKeys",
  CUSTOMKEYS = "customKeys"
}

interface TabItem {
  key: renderAuthorizedKey;
  label: string;
  content: React.ReactElement;
}

const AuthorizedKey = () => {
  const { loginInfo } = store.getState();
  const {keys:authorizedKeyData = []} = loginInfo;
  const { t } = useTranslation(NAMESPACE.READER);
  const [keysData , setKeysData] = useState<Keys[]>([...authorizedKeyData] );


  const filterKeysHandler = (k:string) => {
    let filteredData : any[] = [...authorizedKeyData] ; 

    const filterFunctions:any = {
      [renderAuthorizedKey.ICEKEYS] : isICEKey,
      [renderAuthorizedKey.MOBKEYS] : isMOBKey,
      [renderAuthorizedKey.CUSTOMKEYS] : isCustomKey
    }

    if(filterFunctions[k]){
      filteredData = authorizedKeyData?.filter((data)=>filterFunctions[k](data?.reference || ""))
    }
   setKeysData([...filteredData])
  }

  const onTabClickHandler = (e: any, key: string) => {
     filterKeysHandler(key)
  }

  // showing only tabs which has keys 
  const tabWithKeys = (): TabItem[] => {
    const items:TabItem[] = [ {key: renderAuthorizedKey.ALLKEYS, label: "All Keys", content: <AuthorizedKeyCard data={keysData}/>}]
    authorizedKeyData.forEach((data)=>{
      if(isICEKey(data.reference)){
        items.push({ key: renderAuthorizedKey.ICEKEYS, label: "ICE Keys", content: <AuthorizedKeyCard data={keysData}/>})
      }else if(isMOBKey(data.reference)){
        items.push({ key: renderAuthorizedKey.MOBKEYS, label: "MOB Keys", content: <AuthorizedKeyCard data={keysData}/> })
      }else if(isCustomKey(data.reference)){
        items.push({key: renderAuthorizedKey.CUSTOMKEYS, label: "Custom Keys", content: <AuthorizedKeyCard data={keysData}/>})
      }
    })

    const uniqueItems = Array.from(new Set(items.map((item:any) => item.key)))
    .map(key => items.find((item:any) => item.key === key))
    .filter((item): item is TabItem => item !== undefined);

    return uniqueItems;
  }
  
  return <>
    <main className="hid-fixed-container__main">
      <Typography
        variant={TypographyVariantEnum.ProfileHeader}
        className="hid-spacing__mb-05"
      >
        {t("READERS.AUTHORIZEDKEYS")}
      </Typography>

      <Card>
        <div data-testid="authorizedKeys-tab">
          
          <Tabs
            theme="frameless"
            items={ tabWithKeys() }
            onClick={(e,key) => onTabClickHandler(e,key)}
          />
        </div>

      </Card>
    </main>
  </>
}

export default AuthorizedKey;