import {
    Button,
    Modal,
    Typography,
  } from "@hid-galaxy-ui/galaxy-react";
  import { ModalVariants } from "@hid-galaxy-ui/galaxy-react/components/Modal/modalEnums";
  import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
  import { useTranslation } from "react-i18next";
  import { NAMESPACE } from "../../utils/i18nUtils";
  
  export default function RestrictApplyConfigModal(props: {
    onClose: () => void;
    message: string;
  }) {
    const { onClose , message } = props;
    const { t } = useTranslation(NAMESPACE.READER);
   
    return (
      <Modal size={ModalVariants.Medium} onClose={onClose}>
        <div className="hid-grid hid-grid--center-xs hid-spacing__p-07">
          <Typography
            variant={TypographyVariantEnum.BodyLongMarketing}
            className="hid-spacing__mt-03"
          >
            {message}
          </Typography>
          <div className="hid-flex hid-flex-jc-center hid-spacing__mt-05">
            <Button
              variant="primary"
              onClick={onClose}
              label={t("READERS.BACK_AND_SELECT_READER")}
            />
          </div>
        </div>
      </Modal>
    );
  }