/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { SideNavVariants } from "./SideNavEnums";
import classNames from "classnames";
import {
  Iconography,
  IconographySizes,
  Loader,
  LoaderSizes,
  Tooltip,
} from "@hid-galaxy-ui/galaxy-react";
import { useSelector } from "react-redux";
import { applyTemplateData } from "../../reducers/applyTemplateReducer";
import { useLocation } from "react-router-dom";

interface iSubNavItem {
  title: string;
  key: string;
  disabled?: boolean;
}

interface iNavItem {
  title: string;
  key: string;
  iconName?: string | ReactElement;
  subNavItems?: Array<iSubNavItem>;
  disabled?: boolean;
  hasSubNav?: boolean;
}

export interface SideNavProps {
  isToggle?: boolean;
  mode?: string;
  id: string;
  onNodeClick: (e: React.MouseEvent<HTMLElement>, nodeKey: string) => void;
  navItems: Array<iNavItem>;
  selected?: string;
  noSubNavItemMessage?: string;
  children?: ReactNode;
}

export default function SideNav(props: SideNavProps) {
  const {
    isToggle = true,
    mode = SideNavVariants.Expanded,
    id,
    navItems,
    onNodeClick,
    noSubNavItemMessage = "No Items",
    selected,
    children,
  } = props;
  const [navState, setNavState] = useState(mode);
  const {sideNavActiveKey} =  useSelector(applyTemplateData);
  const [currentSelectedKeys, setCurrentSelectedKeys] = useState<{
    navKey: Array<string>;
    subNavKey: string;
  }>({
    navKey: [],
    subNavKey: selected ? selected : "Readers",
  });

    const location = useLocation();
  
  useEffect(()=>{
    switch(location.pathname){
      case "/readers/apply-template":
          setCurrentSelectedKeys({navKey:[],subNavKey:"SearchTemplate"});
          break;
      case "/authorizedKey":
          setCurrentSelectedKeys({navKey:[],subNavKey:"AuthorizedKey"});
          break;
      default:
          setCurrentSelectedKeys({navKey:[],subNavKey:"Readers"});
          break;
      }
    },[location.pathname])
  

  const onClick = (e: React.MouseEvent<HTMLElement>, nodeKey: string) => {
    setCurrentSelectedKeys({ ...currentSelectedKeys, subNavKey: nodeKey });
    onNodeClick(e, nodeKey);
  };
  const isCurrentNavKeyExists = (item: string) => {
    return currentSelectedKeys.navKey.find((el) => el === item) ? true : false;
  };
  useEffect(() => {
    setNavState(mode);
  }, [mode]);
  useEffect(() => {
    if (
      (selected && isCurrentNavKeyExists(selected)) ||
      selected === currentSelectedKeys.subNavKey
    )
      return;
    navItems.forEach((nav) => {
      if (nav.key === selected) {
        navState === SideNavVariants.Expanded &&
          setCurrentSelectedKeys({
            navKey: [...currentSelectedKeys.navKey, nav.key],
            subNavKey: !nav.subNavItems ? nav.key : "",
          });
        return;
      }
      const selectedSubNav = nav.subNavItems?.find(
        (subNav) => subNav.key === selected
      );

      if (selectedSubNav) {
        navState === SideNavVariants.Expanded &&
          setCurrentSelectedKeys({
            navKey: [...currentSelectedKeys.navKey, nav.key],
            subNavKey: selectedSubNav.key,
          });
        return;
      }
    });
  }, [navItems, selected]);

  const toggleSideNav = () => {
    const currentState =
      navState === SideNavVariants.Expanded
        ? SideNavVariants.Colapsed
        : SideNavVariants.Expanded;
    setNavState(currentState);
    // if (currentState === SideNavVariants.Colapsed) {
    //   setCurrentSelectedKeys({ navKey: [], subNavKey: currentSelectedKeys['subNavKey'] && currentSelectedKeys['subNavKey'] || "Readers" });
    // } 
  };

  const setToggleToCurrentSelected = (key: string, hasSubNav: boolean) => {
    setCurrentSelectedKeys({
      ...currentSelectedKeys,
      navKey: filterArray(key),
    });
    if (hasSubNav) {
      setNavState(SideNavVariants.Expanded);
    }
  };
  const filterArray = (el: string) => {
    const selectedNavKeys = currentSelectedKeys.navKey;
    const selectedIndex = selectedNavKeys?.findIndex((nav) => nav === el);
    if (selectedIndex === -1) {
      selectedNavKeys.push(el);
    } else {
      selectedNavKeys.splice(selectedIndex, 1);
    }
    return selectedNavKeys;
  };

  const renderNavIcon = (iconName: string | ReactElement) => {
    if (typeof iconName === "string") {
      return <Iconography icon={iconName} size={IconographySizes.Medium} />;
    } else {
      return iconName;
    }
  };

  const renderSubNav = (subNavItems: Array<iSubNavItem> | undefined) => {
    return subNavItems && subNavItems?.length > 0 ? (
      <ul className={`hid-side-nav__sub-menu`}>
        {subNavItems?.map((subNavItem: iSubNavItem) => (
          <li className="hid-side-nav__sub-menu-item" key={subNavItem.key}>
            <button
              disabled={subNavItem.disabled}
              id={subNavItem.key}
              className={classNames(
                "hid-side-nav__sub-menu-link hid-text-truncate",
                currentSelectedKeys.subNavKey === subNavItem.key &&
                  !subNavItem.disabled
                  ? "hid-side-nav__sub-menu-link--active"
                  : ""
              )}
              onClick={(e) => onClick(e, subNavItem.key)}
              title={subNavItem.title}
            >
              {subNavItem?.title}
            </button>
          </li>
        ))}
      </ul>
    ) : (
      <div className="hid-text-center hid-side-nav__no-record">
        {noSubNavItemMessage}
      </div>
    );
  };

  const renderNavItem = (navItem: iNavItem) => {
    const hasSubNav = !!(
      (navItem.subNavItems && navItem.subNavItems?.length > 0) ||
      navItem?.hasSubNav
    );
    return (
      <li
        key={navItem.key}
        className={navItem.iconName ? "hid-side-nav--has-icon" : ""}
      >
        <button
          id={navItem.key}
          disabled={navItem.disabled}
          onClick={(e) =>
            hasSubNav
              ? setToggleToCurrentSelected(navItem.key, hasSubNav)
              : onClick(e, navItem.key)
          }
          className={`hid-side-nav__menu-item hid-full-width ${
            currentSelectedKeys.subNavKey === navItem.key && !navItem.disabled
              ? "hid-side-nav__menu-item--active"
              : ""
          }`}
          aria-expanded={currentSelectedKeys.navKey.includes(navItem.key)}
        >
          {navItem.iconName ? (
            <div className="hid-side-nav__icon">
              {navState === SideNavVariants.Expanded ? (
                <span className="hid-layout__mr-03">
                  {renderNavIcon(navItem.iconName)}
                </span>
              ) : (
                <Tooltip interactive={true} message={navItem.title}>
                  <span className="hid-layout__pr-02">
                    {renderNavIcon(navItem.iconName)}
                  </span>
                </Tooltip>
              )}
            </div>
          ) : null}

          {navState === SideNavVariants.Expanded && (
            <>
              <div
                className="hid-side-nav__link hid-text-truncate"
                title={navItem.title}
              >
                {navItem.title}
              </div>
              {hasSubNav && (
                <div className="hid-side-nav__icon">
                  <Iconography
                    icon={
                      currentSelectedKeys.navKey.includes(navItem.key)
                        ? "chevronUp"
                        : "chevronDown"
                    }
                  />
                </div>
              )}
            </>
          )}
        </button>
        {currentSelectedKeys.navKey.includes(navItem.key) && (
          <>
            {navItem.subNavItems && renderSubNav(navItem.subNavItems)}
            {!navItem.subNavItems && navItem?.hasSubNav && (
              <div className="hid-text-center hid-side-nav__loader">
                <Loader variant={LoaderSizes.small} />
              </div>
            )}
          </>
        )}
      </li>
    );
  };

  return (
    <>
      <aside
        className={`hid-side-nav hid-side-nav--${navState.toLowerCase()}`}
        data-testid="sideNav"
        id={id}
        aria-label="Side navigation"
      >
        {isToggle ? (
          <div className="hid-side-nav__collapse-icon">
            <button
              className="hid-side-nav__icon-button"
              onClick={() => toggleSideNav()}
              aria-expanded={navState === SideNavVariants.Expanded}
            >
              <Iconography
                icon={
                  navState === SideNavVariants.Expanded
                    ? "chevronsLeft"
                    : "chevronsRight"
                }
              />
            </button>
          </div>
        ) : null}

        <ul className="hid-side-nav__menu">
          {navItems.map((navItem) => renderNavItem(navItem))}
        </ul>
        {navState === SideNavVariants.Expanded && children && (
          <div className="hid-side-nav__content">{children}</div>
        )}
      </aside>
    </>
  );
}
