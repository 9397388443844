import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReactElement } from "react";
import { RootState } from "../store/store";
import { NotificationVariant } from "@hid-galaxy-ui/galaxy-react/components/Notification/notificationEnums";
import { IFilterObj } from "../pages/Linq/readersApplyTemplate";
import { defaultPageSize } from "../config";
interface InitialStateDataProps {
  notification: { id: string; icon?: ReactElement }[];
  applyTemplateResponse: any;
  selectedReaders: any;
  selectedCorrelationId: string;
  isProgressModalOpen: boolean;
  selectedDCID: { [correlationId: string]: string };
  runningProcesses?: {
    [correlationId: string]: {
      isCompleted: boolean;
      type?: string;
      deviceName?: string;
      deviceDisconnected?: boolean;
      name?:string
    };
  };
  resetReaderResponse: any;
  isResetReaderProgressModalOpen: boolean;
  filterState: IFilterObj | null;
  searchTemplateData?: any;
  searchTemplatePagination?: {
    currentPage?: number;
    pageSize?: number;
    totalRecords?: number;
  };
  selectedReadersForApplyConfig: any;
  sideNavActiveKey: any;
}
const initialState: InitialStateDataProps = {
  notification: [],
  applyTemplateResponse: {},
  selectedReaders: {},
  selectedCorrelationId: "",
  isProgressModalOpen: false,
  selectedDCID: {},
  resetReaderResponse: [],
  isResetReaderProgressModalOpen: false,
  filterState: null,
  searchTemplateData: null,
  searchTemplatePagination: {
    currentPage: 1,
    pageSize: defaultPageSize,
    totalRecords: 0,
  },
  runningProcesses: {},
  selectedReadersForApplyConfig: [],
  sideNavActiveKey: null
};

const applyTemplateSlice = createSlice({
  name: "applyTemplate",
  initialState,
  reducers: {
    setNotification: (
      state,
      action: PayloadAction<
        { id: string; icon?: ReactElement; variant?: NotificationVariant }[]
      >
    ) => {
      state.notification = [...action.payload];
    },
    resetNotification: (state) => {
      state.notification = [];
    },
    setApplyTemplateResponse: (state, action: PayloadAction<any>) => {
      let newData: any = {};
      state.applyTemplateResponse[action.payload.correlationId]?.forEach(
        (c: any) => {
          newData[c.id] = c;
        }
      );
      action.payload[action.payload.correlationId]?.forEach((c: any) => {
        newData[c.id] = c;
      });
      state.applyTemplateResponse = {
        ...state.applyTemplateResponse,
        [action.payload.correlationId]: Object.values(newData),
      };
    },
    setSelectedReaders: (state, action: PayloadAction<any>) => {
      state.selectedReaders = {
        ...state.selectedReaders,
        ...action.payload,
      };
    },
    setSelectedCorrelationId: (state, action: PayloadAction<string>) => {
      state.selectedCorrelationId = action.payload;
    },
    setIsProgressModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isProgressModalOpen = action.payload;
    },
    setSelectedDCIDTemplate: (state, action: PayloadAction<any>) => {
      state.selectedDCID = { ...state.selectedDCID, ...action.payload };
    },
    setRunningProcesses: (state, action: PayloadAction<any>) => {
      state.runningProcesses = { ...state.runningProcesses, ...action.payload };
    },
    removeRunningProcesses: (state, action: PayloadAction<string>) => {
      if (state.runningProcesses) {
        delete state.runningProcesses[action.payload];
      }
    },
    setResetReaderResponse: (state, action: PayloadAction<any>) => {
      state.resetReaderResponse = {
        ...state.resetReaderResponse,
        ...action.payload,
      };
    },
    setIsResetReaderProgressModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isResetReaderProgressModalOpen = action.payload;
    },
    removeReaders: (state, action: PayloadAction<string>) => {
      if (state.selectedReaders) {
        delete state.selectedReaders[action.payload];
      }
    },
    resetStateToDefault: (state) => {
      // Reset each field in the state to its default value
      state.notification = [];
      state.applyTemplateResponse = {};
      // state.selectedReaders = {};
      state.selectedCorrelationId = "";
      state.isProgressModalOpen = false;
      state.selectedDCID = {};
      state.runningProcesses = {};
      state.resetReaderResponse = [];
      state.isResetReaderProgressModalOpen = false;
      state.filterState = null;
      state.searchTemplatePagination = {
        currentPage: 1,
        pageSize: defaultPageSize,
        totalRecords: 0,
      };
      state.searchTemplateData = null;
      state.selectedReadersForApplyConfig = [];
      state.sideNavActiveKey = null;
    },
    setFilterState: (state, action: PayloadAction<IFilterObj | null>) => {
      state.filterState = action.payload;
    },
    setSearchTemplateData: (state, action: PayloadAction<any>) => {
      state.searchTemplateData = action.payload;
    },
    setSelectedReadersForApplyConfig: (state, action: PayloadAction<any>) => {
      state.selectedReadersForApplyConfig = action.payload;
    },
    setSideNavActiveKey: (state, action: PayloadAction<any>) => {
      state.sideNavActiveKey = action.payload;
    },
    resetSelectedReadersForApplyConfig: (state) => {
      state.selectedReadersForApplyConfig = [];
    },
    setSearchTemplatePagination: (
      state,
      action: PayloadAction<{
        currentPage?: number;
        pageSize?: number;
        totalRecords?: number;
      }>
    ) => {
      state.searchTemplatePagination = {
        ...state.searchTemplatePagination,
        ...action.payload,
      };
    },
  },
});

export default applyTemplateSlice.reducer;
export const {
  setNotification,
  setApplyTemplateResponse,
  setSelectedReaders,
  setSelectedCorrelationId,
  setIsProgressModalOpen,
  setSelectedDCIDTemplate,
  setRunningProcesses,
  removeRunningProcesses,
  setResetReaderResponse,
  setIsResetReaderProgressModalOpen,
  removeReaders,
  resetNotification,
  resetStateToDefault,
  setFilterState,
  setSearchTemplateData,
  setSearchTemplatePagination,
  setSelectedReadersForApplyConfig,
  resetSelectedReadersForApplyConfig,
  setSideNavActiveKey
} = applyTemplateSlice.actions;
export const applyTemplateData = (state: RootState) => state.applyTemplate;
