import {
  Button,
  Iconography,
  IconographySizes,
  Loader,
  Notification,
  NotificationVariants,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import {
  applyTemplateData,
  setIsResetReaderProgressModalOpen,
  setIsProgressModalOpen,
  setNotification,
  removeRunningProcesses,
  setSelectedCorrelationId,
  removeReaders,
  setRunningProcesses,
} from "../../reducers/applyTemplateReducer";
import readerBlue from "../../assets/svgs/reader-blue.svg";
import readergreen from "../../assets/svgs/reader_green_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";
import ApplyTemplateProgress, {
  ApplyTemplateStatus,
} from "../../pages/Linq/readersApplyTemplate/applyTemplateProgress";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { LoaderSizes } from "@hid-galaxy-ui/galaxy-react/components/Loader/loaderEnums";
import "./applyTemplateNotification.scss";
import { unSubscribe } from "../../utils/SocketInit";
import ApplyResetReader, {
  resetApplyStatus,
} from "../../pages/Linq/readers/applyResetReader";
import {
  resetFWStateToDefault,
  setUpgradeFirmwareProgressModalOpen,
  upgradeFirmwareData,
} from "../../reducers/upgradeFirmwareReducer";
import UpdateFirmwareProgress from "../../pages/Linq/upgradeFirmware/UpdateFirmwareModal/updateFirmwareProgress";
import NotificationWithProgress from "./NotificationWithProgress";
import { useEffect } from "react";

export default function ApplyTemplateNotification() {
  const {
    notification,
    isProgressModalOpen,
    isResetReaderProgressModalOpen,
    runningProcesses = {},
    selectedCorrelationId,
    applyTemplateResponse,
    resetReaderResponse,
    selectedReaders,
  } = useSelector(applyTemplateData);
  const { isUpgradeFirmwareProgressModalOpen, updateFirmwareResponse } =
    useSelector(upgradeFirmwareData);
  const isCompleted =
    (runningProcesses &&
      runningProcesses[selectedCorrelationId]?.isCompleted) ||
    false;
  const applyTemplateResponseData =
    applyTemplateResponse[selectedCorrelationId];
  const resetReaderResponseData = resetReaderResponse[selectedCorrelationId];
  const jobType =
    (runningProcesses && runningProcesses[selectedCorrelationId]?.type) || "";

  const deviceName =
    (runningProcesses && runningProcesses[selectedCorrelationId]?.deviceName) ||
    "";
  const updateFirmwareData = updateFirmwareResponse[selectedCorrelationId];

  const isDeviceDisconnected =
    runningProcesses?.[`${selectedCorrelationId}`]?.deviceDisconnected || false;

  const dispatch = useDispatch();
  const { t } = useTranslation(NAMESPACE.READER);
  const processKeys = Object.keys(runningProcesses);
  function handleRightArrowClick() {
    const currentIndex = processKeys.indexOf(selectedCorrelationId);
    const currentProcess = runningProcesses[selectedCorrelationId];
    let nextIndex = (currentIndex + 1) % processKeys.length;
  
    // If the current type is "upgrade", find the next process that is not "upgrade"
    if (currentProcess?.type === "upgrade") {
      while (runningProcesses[processKeys[nextIndex]].type === "upgrade") {
        nextIndex = (nextIndex + 1) % processKeys.length;
      }
    }
  
    // Select the next process
    let selectedId = processKeys[nextIndex];
    dispatch(setSelectedCorrelationId(selectedId));
  }
  useEffect(() => {
    if (isDeviceDisconnected) {
      dispatch(
        setNotification([
          {
            id: "1",
            icon: <Iconography icon="circleX" />,
            variant: NotificationVariants.Error,
          },
        ])
      );
    }
  }, [dispatch, isDeviceDisconnected]);

  function handleLeftArrowClick() {
    const currentIndex = processKeys.indexOf(selectedCorrelationId);
    const currentProcess = runningProcesses[selectedCorrelationId];
    let nextIndex = (currentIndex - 1 + processKeys.length) % processKeys.length;
  
    // If the current type is "upgrade", find the previous process that is not "upgrade"
    if (currentProcess?.type === "upgrade") {
      while (runningProcesses[processKeys[nextIndex]].type === "upgrade") {
        nextIndex = (nextIndex - 1 + processKeys.length) % processKeys.length;
      }
    }
  
    // Select the previous process
    let selectedId = processKeys[nextIndex];
    dispatch(setSelectedCorrelationId(selectedId));
  }
  useEffect(() => {
    switch (jobType) {
      case "reset":
        if (resetReaderResponseData) {
          if (
            resetReaderResponseData?.[0]?.applyDcidStatus ===
            resetApplyStatus.SUCCESS
          ) {
            dispatch(
              setNotification([
                {
                  id: "1",
                  icon: <Iconography icon="circleCheck" />,
                  variant: NotificationVariants.Success,
                },
              ])
            );
          } else {
            dispatch(
              setNotification([
                {
                  id: "1",
                  icon: <Iconography icon="circleX" />,
                  variant: NotificationVariants.Error,
                },
              ])
            );
          }
        } else {
          dispatch(
            setNotification([
              {
                id: selectedCorrelationId,
                icon: isCompleted ? (
                  renderIcon()
                ) : (
                  <img
                    className="hid-spacing__pr-05"
                    src={readerBlue}
                    alt="reader-icon"
                  />
                ),
                variant: isCompleted
                  ? getNotificationVariant()
                  : NotificationVariants.Info,
              },
            ])
          );
        }
        break;
      case "applyTemplate":
        if (isDeviceDisconnected) {
          unSubscribe(selectedCorrelationId);
        }
        dispatch(
          setNotification([
            {
              id: selectedCorrelationId,
              icon: renderIcon(),
              variant: getNotificationVariant(),
            },
          ])
        );
        break;
        case "upgrade":
          onCloseUpgradeFirmwareWindow();
    }
  }, [dispatch, isCompleted, resetReaderResponseData, selectedCorrelationId]);

  useEffect(() => {
    const readers = selectedReaders[selectedCorrelationId];
    const readersCount = Array.isArray(readers)
      ? readers.length
      : readers?.readersCount;
    const currentResponse = applyTemplateResponse[selectedCorrelationId];
    if (currentResponse?.length >= readersCount) {
      unSubscribe(selectedCorrelationId);
      dispatch(
        setRunningProcesses({
          [selectedCorrelationId]: { isCompleted: true, type: "applyTemplate" },
        })
      );
    }
  }, [applyTemplateResponse[selectedCorrelationId]]);

  const setNotificationContent = (
    data: {
      id: string;
      icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    }[]
  ) => {
    return dispatch(setNotification(data));
  };
  const onCloseWindow = () => {
    dispatch(setIsProgressModalOpen(false));
    dispatch(
      setNotification([
        {
          id: selectedCorrelationId,
          icon: isCompleted ? (
            renderIcon()
          ) : (
            <img
              className="hid-spacing__pr-05"
              src={readerBlue}
              alt="reader-icon"
            />
          ),
          variant: isCompleted
            ? getNotificationVariant()
            : NotificationVariants.Info,
        },
      ])
    );
  };
  const onCloseUpgradeFirmwareWindow = () => {
    const failedCount = updateFirmwareData?.filter(
      (c: any) =>
        c.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_FAILED" ||
        c.updateFirmwareOverallStatus === "DEVICE_FW_UPGRADE_SYNC_FAILED"
    );
    dispatch(setUpgradeFirmwareProgressModalOpen(false));
    if (isDeviceDisconnected) {
      dispatch(
        setNotification([
          {
            id: "1",
            icon: <Iconography icon="circleX" />,
            // variant: NotificationVariants.Error,
          },
        ])
      );
    } else {
      dispatch(
        setNotification([
          {
            id: "1",
            icon: <Iconography icon={isCompleted ? failedCount?.length>0 && failedCount?.length === updateFirmwareData?.length ? "circleX" : "arrowsRepeat" :"arrowsRepeat"} />,
            variant: isCompleted
              ? failedCount?.length > 0
                ? failedCount.length === updateFirmwareData.length
                  ? NotificationVariants.Error
                  : NotificationVariants.Warning
                : NotificationVariants.Success
              : NotificationVariants.Info,
          },
        ])
      );
    }
  };
  const allProcessCompleted = (processes: any) =>
    Object.values(processes)
      // .filter((p: any) => p.type === "update")
      .every((process: any) => process.isCompleted);

  
  const getConfigurationTemplateMessage = () => {
    const successReaders = applyTemplateResponseData?.filter(
      (c: any) => c.applyDcidStatus === ApplyTemplateStatus.SUCCESS
    );
    const failedReaders = applyTemplateResponseData?.filter(
      (c: any) => c.applyDcidStatus === ApplyTemplateStatus.FAILURE
    );
    if (isDeviceDisconnected) {
      return t("READERS.OPERATION_INTERRUPTED");
    }
    if (failedReaders?.length > 0 && successReaders?.length > 0) {
      const partialErrorMessage =
        failedReaders[0]?.configurationStatus ||
        t("READERS.CONFIGURATION_APPLY_FAILED");
      return `${t(
        "READERS.CONFIGURATION_FAILED_FOR_ONE_OR_MORE_READERS"
      )}: ${partialErrorMessage}`;
    }
    return successReaders?.length > 0
      ? applyTemplateResponseData?.[0]?.configurationStatus ||
          t("READERS.CONFIGURATION_APPLIED")
      : applyTemplateResponseData?.[0]?.configurationStatus ||
          t("READERS.CONFIGURATION_APPLY_FAILED");
  };
  const getNotificationVariant = () => {
    const failedReaders = applyTemplateResponseData?.filter(
      (c: any) => c.applyDcidStatus === ApplyTemplateStatus.FAILURE
    );

    if (!runningProcesses?.[`${selectedCorrelationId}`]?.isCompleted) {
      return NotificationVariants.Info;
    } else {
      if (isDeviceDisconnected) {
        return NotificationVariants.Error;
      }
      return failedReaders?.length > 0
        ? NotificationVariants.Error
        : NotificationVariants.Success;
    }
  };
  const renderIcon = () => {
    const failedReaders = applyTemplateResponseData?.filter(
      (c: any) => c.applyDcidStatus === ApplyTemplateStatus.FAILURE
    );

    if (!runningProcesses?.[`${selectedCorrelationId}`]?.isCompleted) {
      return (
        <img
          className="hid-spacing__pr-05"
          src={readerBlue}
          alt="reader-icon"
        />
      );
    } else {
      return failedReaders?.length > 0 ? (
        <Iconography icon="circleX" />
      ) : (
        <img
          className="hid-spacing__pr-05"
          src={readergreen}
          alt="reader-icon"
        />
      );
    }
  };
  const getResetStatus = () => {
    // const selectedReader=
    return isCompleted
      ? resetReaderResponseData?.[0]["applyDcidStatus"] ===
        resetApplyStatus.SUCCESS
        ? resetReaderResponseData?.[0]["configurationStatus"] ||
          t("READERS.RESET_APPLIED")
        : resetReaderResponseData?.[0]["configurationStatus"] ||
          t("READERS.RESET_FAILED")
      : t("READERS.APPLYING_RESET");
  };
  return (
    <div className="process-notification">

      <Notification
        toasts={Array.isArray(notification) ? notification : []}
        setToasts={setNotificationContent}
        isAutoClose={false}
        className="progress-notification"
      >
        {jobType === "upgrade" ? (
          <NotificationWithProgress
            runningProcesses={runningProcesses}
            handleLeftArrowClick={handleLeftArrowClick}
            handleRightArrowClick={handleRightArrowClick}
            isCompleted={isCompleted}
            updateFirmwareResponse={updateFirmwareResponse}
            setNotificationContent={setNotificationContent}
            selectedCorrelationId={selectedCorrelationId}
          />
        ) : (
          <div className="hid-grid hid-flex-ai-center hid-flex-jc-between">
            {/* {!isCompleted && (
            <div className="hid-grid__column hid-grid__column--1-xs">
              <Loader variant={LoaderSizes.small} />
            </div>
          )}  */}
            <div
              className={`hid-grid ${
                processKeys.length > 1
                  ? "hid-grid__column--10-xs"
                  : "hid-grid__column--11-xs"
              }  hid-grid--between-xs`}
              style={{ alignItems: "center" }}
            >
              <div className="hid-flex hid-grid__column--9-xs">
                {!runningProcesses?.[selectedCorrelationId]?.isCompleted &&
                !runningProcesses?.[selectedCorrelationId]?.type?.includes(
                  "error"
                ) ? (
                  <div className="hid-spacing__ml-03 hid-flex hid-flex-align-center">
                    <Loader variant={LoaderSizes.small} />
                  </div>
                ) : null}
                <Typography
                  className="hid-spacing__ml-03"
                  variant={TypographyVariantEnum.BodyShortProduct}
                >
                  {jobType === "reset"
                    ? getResetStatus()
                    : jobType === "upgrade"
                    ? isCompleted
                      ? t("READERS.RESET_APPLIED")
                      : t("Upgrading Firmware")
                    : isCompleted
                    ? getConfigurationTemplateMessage()
                    : t("READERS.APPLYING_DCID")}
                </Typography>
              </div>
              <div className="hid-grid__column--3-xs hid-text-right">
                {isCompleted ? (
                  <Button
                    variant="primary"
                    label="Ok"
                    onClick={() => {
                      if (Object.keys(runningProcesses)?.length > 1) {
                        handleRightArrowClick();
                      } else {
                        setNotificationContent([]);
                      }
                      dispatch(removeRunningProcesses(selectedCorrelationId));
                      dispatch(removeReaders(selectedCorrelationId));
                      unSubscribe(selectedCorrelationId);
                    }}
                  />
                ) : (
                  <Button
                    variant="primary"
                    label="View"
                    onClick={() => {
                      jobType === "reset"
                        ? dispatch(setIsResetReaderProgressModalOpen(true))
                        : dispatch(setIsProgressModalOpen(true));
                    }}
                  />
                )}
              </div>
            </div>

            {processKeys.length > 1 ? (
              <div className="hid-grid__column hid-grid__column--2-xs hid-text-center arrow-container">
                <Iconography
                  icon="circleChevronLeft"
                  size={IconographySizes.Medium}
                  className={
                    Object.keys(runningProcesses).length > 1
                      ? "default-color"
                      : "disabled-color"
                  }
                  onClick={handleLeftArrowClick}
                />
                <Iconography
                  icon="circleChevronRight"
                  size={IconographySizes.Medium}
                  className={
                    Object.keys(runningProcesses).length > 1
                      ? "default-color"
                      : "disabled-color"
                  }
                  onClick={handleRightArrowClick}
                />
              </div>
            ) : null}
          </div>
        )}
      </Notification>
      {isResetReaderProgressModalOpen ? (
        <ApplyResetReader deviceName={deviceName} />
      ) : isProgressModalOpen ? (
        <ApplyTemplateProgress
          isOpen={isProgressModalOpen}
          onClose={() => onCloseWindow()}
        />
      ) : isUpgradeFirmwareProgressModalOpen  ? (
        <UpdateFirmwareProgress onClose={onCloseUpgradeFirmwareWindow} />
      ) : null}
    </div>
  );
}
