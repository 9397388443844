import {
  Button,
  Divider,
  Iconography,
  Tooltip,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import {
  IconographySizes,
  IconographyThemes,
} from "@hid-galaxy-ui/galaxy-react/components/Iconography";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import Device from "../../../../assets/images/device.png";
import dcidIcon from "../../../../assets/svgs/dcid_icon.svg";
import { ITableData, renderPageEnum } from "..";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import EditReader from "../editReader";
import ReaderSpecs from "../readerSpecifications";
import ResetReader from "../resetReader";
import { setSelectedReaders } from "../../../../reducers/upgradeFirmwareReducer";
import { useDispatch, useSelector } from "react-redux";
import { readersApplyTemplatePath, updateFirmwarePath } from "../../../../utils/routes";
import "./readerDetails.scss";
import {
  discoveredReadersData,
  setPageFrom,
  setReaders,
} from "../../../../reducers/discoverReadersReducer";
import { changeReaderNameService } from "../../../../services/readerAPIService";
import { loginInfoData } from "../../../../reducers/userInfoReducer";
import { isUSBReader } from "../../../../utils/commonConst";
import { isVentoReader } from "../../../../utils/linq_utils";
import { setSideNavActiveKey, setSelectedReadersForApplyConfig } from "../../../../reducers/applyTemplateReducer";

interface IReadersViewProps {
  selectedRow: ITableData | undefined;
  rowData: ITableData[] | undefined;
  onLocateReader?: (data: string) => void;
  backToReaderList?: () => void;
  locateReaderStatus?: string | null;
  selectedGatewayDevice: string;
}

export default function ReadersView(props: IReadersViewProps) {
  const {
    backToReaderList,
    // rowData,
    onLocateReader,
    locateReaderStatus,
    selectedGatewayDevice,
  } = props;
  const { dicoveredReaders, selectedReaderRow: selectedRow , pageFrom } = useSelector(
    discoveredReadersData
  );
  const { selectedGateway } = useSelector(loginInfoData);
  const rowData =
    (dicoveredReaders && dicoveredReaders[selectedGateway]?.readers) || [];
  const [displayData, setDisplayData] = useState<any>(selectedRow);
  const [count, setCount] = useState<any>(
    rowData?.findIndex((element) => element.id === selectedRow?.id)
  );
  const [editReader, setEditReader] = useState<boolean>(false);
  const [showReaderSpecs, setShowReaderSpecs] = useState<boolean>(false);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [showApplyResetModal, setShowApplyResetModal] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedCustomerInfo } = useSelector(loginInfoData);
  const customerId = selectedCustomerInfo?.customerId?.toString();
  function handleShowData(value: number) {
    const data = rowData?.[value];
    setDisplayData(data);
  }
  const handleListShow = (move: string) => {
    if (move === "next") {
      setCount(count + 1);
      handleShowData(count + 1);
    } else if (count > 0) {
      setCount(count - 1);
      handleShowData(count - 1);
    }
  };
  const handleEditReader = () => {
    setEditReader(!editReader);
  };
  const handleReaderAction = (action: string) => {
    switch (action) {
      case t("READERS.INSPECT_READER"):
        setShowReaderSpecs(!showReaderSpecs);
        break;
      case t("READERS.LOCATE"):
        rowData && onLocateReader && onLocateReader(rowData[count].id);
        break;
      case t("READERS.ADVANCED"):
        setShowResetModal(!showResetModal);
        setShowApplyResetModal(false);
        break;
      case t("READERS.UPDATE_FIRMWARE"):
        selectedRow && dispatch(setSelectedReaders([displayData]));
        dispatch(setPageFrom("carousel"));
        navigate(updateFirmwarePath);
        break;
      case t("READERS.APPLY_CONFIGURATION_ID"):
        const selectedData = [{...displayData}]
        dispatch(setPageFrom("carousel"));
        dispatch(setSelectedReadersForApplyConfig(selectedData));
         dispatch(setSideNavActiveKey("SearchTemplate"));
        navigate(readersApplyTemplatePath);
        break;
      default:
      // Default case code here
    }
  };
  const { t } = useTranslation(NAMESPACE.READER);
  const readersData = [
    {
      heading: t("READERS.INSPECT_READER"),
      icon: "tablet",
      className: "disabled-colour",
      disabled: true,
    },
    {
      heading: t("READERS.APPLY_CONFIGURATION_ID"),
      icon: "windowRestore",
      className: (!!displayData?.fwUpdateRequired) || ( (!displayData?.operationAllowed && displayData?.restrictionReason?.length>=1) || isVentoReader(displayData?.readerType || "") ) ? "disabled-colour" : "default-color",
      // disabled: true,
      disabled: (!!displayData?.fwUpdateRequired) || (!displayData?.operationAllowed && displayData?.restrictionReason?.length>=1 || isVentoReader(displayData?.readerType || "") ),
      title: isVentoReader(displayData?.readerType || "") ? "Configuration changes are restricted for Vento readers" : !!displayData?.fwUpdateRequired ? "Firmware Update Required" : !displayData?.operationAllowed && displayData?.restrictionReason?.length>=1 ? displayData?.restrictionReason  : ""
    },
    {
      heading: t("READERS.UPDATE_FIRMWARE"),
      icon: "arrowsRepeat",
      className: (!displayData?.operationAllowed && displayData?.restrictionReason?.length>=1) ? "disabled-colour" :  "default-color",
      disabled: (!displayData?.operationAllowed && displayData?.restrictionReason?.length>=1) || !isUSBReader(displayData.port),
      title: (!displayData?.operationAllowed && displayData?.restrictionReason?.length>=1) ? displayData?.restrictionReason :  !isUSBReader(displayData.port)?t("READERS.MERCURY_READER_ERROR"):""
    },
    {
      heading: t("READERS.LOCATE"),
      icon: "volume",
      className:
        locateReaderStatus === "success"
          ? "success-color"
          : locateReaderStatus === "failed"
          ? "filure-color"
          : "default-color",
    },
    {
      heading: t("READERS.HISTORY"),
      icon: "clockRotateLeft",
      className: "disabled-colour",
      disabled: true,
    },
    {
      heading: t("READERS.ADVANCED"),
      icon: "ellipsisVertical",
      title: (!displayData?.operationAllowed && displayData?.restrictionReason?.length>=1) ? displayData?.restrictionReason :
       (
        isVentoReader(displayData.readerType || "")
          ? "Configuration changes are restricted for Vento readers"
          : !!displayData.fwUpdateRequired ? "Firmware Update Required" : "" ),
      className: ` ${
       ((!displayData?.operationAllowed && displayData?.restrictionReason?.length>=1) || !!displayData.fwUpdateRequired ||
        isVentoReader(displayData.readerType || "") )
          ? "disabled-colour"
          : "default-color"
      } rotate-90-deg`,
      disabled: (!displayData?.operationAllowed && displayData?.restrictionReason?.length>=1) 
      || ( displayData.fwUpdateRequired ||
        isVentoReader(displayData.readerType || "") ),
    },
  ];
  const handleEditReaderName = async (data: any) => {
    const res =
      customerId &&
      (await changeReaderNameService(
        data,
        selectedGatewayDevice,
        customerId,
        displayData.id
      ));
    const name = data.deviceName;
    setDisplayData({ ...displayData, readerName: name });
    const updatedReaders = rowData?.map((reader) =>
      reader.id === displayData.id ? { ...reader, readerName: name } : reader
    );
    if (updatedReaders) {
      dispatch(
        setReaders({
          data: { readers: updatedReaders, timestamp: Date.now().toString() },
          gateway: selectedGatewayDevice,
        })
      );
    }

    setEditReader(false);
  };
  const renderMetaData = (
    icon: ReactNode,
    value: string,
    className?: string
  ) => (
    <div className="hid-grid__column hid-grid__column--4-xs">
      <div className="hid-layout__my-03">
        <div className="hid-flex">
          <div className={`hid-flex hid-flex-ai-center  ${className}`}>
            {icon}
            <Typography
              className={`hid-layout__ml-01 ${className}`}
              variant={TypographyVariantEnum.BodyShortProduct}
            >
              {value}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div data-testid="readers-details">
      <div className="hid-origo__white-card hid-layout__mt-02">
        <div className="hid-flex hid-flex-ai-center hid-origo__settings-btn">
          <Button
            variant="secondary"
            onClick={backToReaderList}
            label={
              <>
                <Iconography
                  icon="arrowLeftLong"
                  className="hid-spacing__mr-03"
                />
                {t("READERS.BACK_TO_LIST")}
              </>
            }
          />
        </div>
        <div className="hid-flex hid-flex-jc-center">
          <div className="hid-flex hid-flex-ai-center">
            <Button
              variant="secondary"
              icon={
                <Iconography icon="angleLeft" size={IconographySizes.Medium} />
              }
              onClick={() => handleListShow("prev")}
              disabled={count === 0}
            />
          </div>
          <img src={Device} alt="device" />
          <div className="hid-flex hid-flex-ai-center">
            <Button
              variant="secondary"
              icon={
                <Iconography icon="angleRight" size={IconographySizes.Medium} />
              }
              onClick={() => handleListShow("next")}
              disabled={rowData && count === rowData?.length - 1}
            />
          </div>
        </div>
        <div className="hid-flex hid-flex-jc-center">
          <div
            className="hid-flex  hid-layout__ml-05"
            data-testd="readers-name"
          >
            <Typography
              variant={TypographyVariantEnum.BodyLongProduct}
              className="header-text"
            >
              {displayData?.id}
            </Typography>
            <Button
              variant="secondary"
              icon={<Iconography icon="pen" size={IconographySizes.Medium} />}
              onClick={handleEditReader}
              disabled={true}
            />
          </div>
        </div>
        {/* <Typography
          className="hid-text-center"
          variant={TypographyVariantEnum.BodyLongProduct}
        >
          {t("READERS.READER_DISCRIPTION")}
        </Typography> */}
        <div className="hid-layout__mt-04 hid-layout__px-10">
          <Divider />
          <div className="hid-grid ml-20">
            {renderMetaData(
              <img src={dcidIcon} alt="dcid" />,
              displayData?.dcid,
              "color-black"
            )}
            {renderMetaData(
              <Iconography
                size={IconographySizes.Medium}
                icon={"arrowsRepeat"}
              />,
              displayData?.fwDisplayName,
              "color-black"
            )}
            {renderMetaData(
              <Iconography size={IconographySizes.Medium} icon={"sitemap"} />,
              displayData?.controllerName
            )}
            {renderMetaData(
              <Iconography size={IconographySizes.Medium} icon={"barcode"} />,
              displayData?.serialNumber || "Serial Number",
              "disabled-colour"
            )}
            {renderMetaData(
              <Iconography
                size={IconographySizes.Medium}
                icon={"lockHashtag"}
              />,
              displayData?.id
            )}
            {renderMetaData(
              <Iconography size={IconographySizes.Medium} icon={"tablet"} />,
              displayData?.readerType
            )}
          </div>
          <Divider />
          <div className="hid-layout__p-04" data-testid="focus">
            <div className="hid-grid ">
              <div className="hid-grid__column--3-xs"></div>
              <div className="hid-grid hid-grid__column--6-xs">
                {readersData.map((data, index) => (
                  <div className="hid-grid__column hid-grid__column--4-xs  hid-spacing__my-05 hid-text-center">
                    {data.title ? (
                      <Tooltip message={data.title}>
                        <Iconography
                          size={IconographySizes.Medium}
                          icon={data.icon}
                          className={`hid-layout__mb-04 ${data.className}`}
                          onClick={() =>
                            !data.disabled && handleReaderAction(data.heading)
                          }
                          disabled={data.disabled}
                        />
                        <br />
                        <Button
                          label={data.heading}
                          variant="secondary"
                          onClick={() => handleReaderAction(data.heading)}
                          disabled={data.disabled}
                        />
                      </Tooltip>
                    ) : (
                      <>
                        <Iconography
                          size={IconographySizes.Medium}
                          icon={data.icon}
                          className={`hid-layout__mb-04 ${data.className}`}
                          onClick={() =>
                            !data.disabled && handleReaderAction(data.heading)
                          }
                          disabled={data.disabled}
                        />
                        <br />
                        <Button
                          label={data.heading}
                          variant="secondary"
                          onClick={() => handleReaderAction(data.heading)}
                          disabled={data.disabled}
                        />
                      </>
                    )}
                  </div>
                ))}
              </div>
              <div className="hid-grid__column--3-xs"></div>
            </div>
          </div>
        </div>
      </div>
      {editReader && (
        <EditReader
          readerName={displayData?.readerName}
          description=""
          setModalOpen={handleEditReader}
          onSave={handleEditReaderName}
        />
      )}
      {showReaderSpecs && (
        <ReaderSpecs setModalOpen={() => setShowReaderSpecs(false)} />
      )}
      {showResetModal && (
        <ResetReader
          closeModal={() => {
            setShowResetModal(false);
          }}
          applyResetOpen={false}
          deviceDetails={rowData ? rowData[count] : []}
          selectedGatewayDevice={selectedGatewayDevice}
        />
      )}
    </div>
  );
}
