import {
  Button,
  Iconography,
  IconographySizes,
  SearchInput,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { Keys } from ".";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../utils/i18nUtils";
import "./_authorizedKeys.scss";
import { useEffect, useState } from "react";
import ListView from "./ListView";

interface Props {
  data: Keys[];
}

const AuthorizedKeyCard = (props: Props) => {
  const { data: keys } = props;
  const [keysData, setKeysData] = useState([...keys]);
  const { t } = useTranslation(NAMESPACE.READER);
  const [isSortedAscending, setIsSortedAscending] = useState<boolean>(false);
  const [search, setSearch] = useState("");

  // this logic is for tab navigation
  useEffect(() => {
    setSearch("");
    setKeysData([...keys]);
  }, [keys]);

  const onKeyNameSearchHandler = (event: any) => {
    const val: string = event.target.value;
    setSearch(val);
    const filteredKeysData =
      keys?.filter((key) =>
        key?.reference
          ?.trim()
          ?.toLowerCase()
          .includes(val?.trim()?.toLowerCase())
      ) || [];
    setKeysData([...filteredKeysData]);
  };

  const onSortHandler = () => {
    let sortedKeysData: Keys[] = [];

    if (!isSortedAscending) {
      sortedKeysData = keysData.sort((a: any, b: any) =>
        a?.issuedOn?.localeCompare(b?.issuedOn)
      );
    } else {
      sortedKeysData = keysData.sort((a: any, b: any) =>
        b?.issuedOn?.localeCompare(a?.issuedOn)
      );
    }
    setKeysData([...sortedKeysData]);
    setIsSortedAscending((prev) => !prev);
  };

  const isAdmin = () => {
    return keys?.some((a) => a?.issuedOn);
  };

  return (
    <main
      className="hid-authorizedkey-container"
      data-testid="authorizedKeyCard"
    >
      <div className="hid-authorizedKey-cardContainer">
        <div>
          <SearchInput
            value={search}
            id="search"
            name="search"
            placeholder="Search"
            data-testid="search"
            onSearch={(e: any) => onKeyNameSearchHandler(e)}
            onChange={(e: any) => onKeyNameSearchHandler(e)}
          />
        </div>
        {isAdmin() && (
          <Button
            variant="flat"
            className="sortButton"
            icon={
              <Iconography
                icon={
                  isSortedAscending ? "arrowUpShortWide" : "arrowDownShortWide"
                }
                size={IconographySizes.Medium}
              />
            }
            onClick={onSortHandler}
          />
        )}
      </div>

      <br />
      {/** If keys is less than 30 then grid view */}
      {/* {keys.length!==0 && keys.length <= AUTHORIZEDKEYSGRIDVIEWLIMIT  && <div className="hid-authorizedKeys-gridContainer" >

    {keysData && Array.isArray(keysData) && keysData?.length>=1 && 
      <GridView data={keysData}/>
   }
    </div> } */}

      {
        <div className="hid-authorizedKeys-listContainer">
          {keysData && Array.isArray(keysData) && keysData?.length >= 1 && (
            <ListView data={keysData} />
          )}
        </div>
      }

      {/** If no keys present  */}
      {keysData && keysData.length === 0 && (
        <Typography variant={TypographyVariantEnum.Label}>
          {t("READERS.INVALIDAUTHORIZEDKEYS")}{" "}
        </Typography>
      )}
    </main>
  );
};

export default AuthorizedKeyCard;
