import {
  Iconography,
  IconographySizes,
  Link,
  Loader,
  Modal,
  ModalVariants,
  Tooltip,
  TooltipPlacements,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { useEffect, useRef, useState } from "react";
import {
  discoverReaderService,
  getGatewayDeviceService,
  locateReaderService,
} from "../../../services/readerAPIService";
import { IDevices } from "../readers/downloadGateway/types";
import Device from "../../../assets/images/device.png";
import { IOption } from "@hid-galaxy-ui/galaxy-react/components/DropDown/IDropdownProps";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../utils/i18nUtils";
import "./readerSelectModal.scss";
import { ITableData, deviceDiscoveryEnum } from "../readers";
import ReadersTable from "./readersTable";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import ReviewApplyDCID from "./reviewApplyDCID";
import { connectToStomp } from "../../../utils/SocketInit";
import { IReadersData, IGateway } from "./types";
import { useDispatch, useSelector } from "react-redux";
import {
  loginInfoData,
  setGlobalToasts,
} from "../../../reducers/userInfoReducer";

import { AsyncPaginate } from "react-select-async-paginate";
import { buildNotification } from "../../../utils/notification";
import {
  discoveredReadersData,
  resetReaders,
  setPageFrom,
  setReaders,
} from "../../../reducers/discoverReadersReducer";
import { isVentoReader } from "../../../utils/linq_utils";
import { isUSBReader } from "../../../utils/commonConst";
import { applyTemplateData, resetSelectedReadersForApplyConfig, setSideNavActiveKey } from "../../../reducers/applyTemplateReducer";
interface IReaderSelectModal {
  isOpen: boolean;
  onClose?: () => void;
  onApplyTemplateConfirm?: (
    data: IGateway,
    selectedReaders: IReadersData[],
    restrictProtocol: boolean | string,
    restrictOsdpVersionChange: boolean | string,
    resetApplyConfigFn?:any
  ) => void;
  selectedDCID: any;
}
interface IsiteAndSystem {
  gateway: IOption | null;
}
interface IRecordsRef {
  totalRecords: number;
  devices: any[];
}
let topic: { id: string; unsubscribe: () => void };

export default function ReaderSelectModal(props: IReaderSelectModal) {
  const { t } = useTranslation(NAMESPACE.READER);
  const dispatch = useDispatch();
  const { selectedReadersForApplyConfig } = useSelector(applyTemplateData);
  const { isOpen, selectedDCID, onClose, onApplyTemplateConfirm } = props;
  const [view, setView] = useState<string>("reader");
  const [selectedReaders, setSelectedReaders] = useState<IReadersData[]>([]);
  const [dropdownData, setDropdownData] = useState<IsiteAndSystem>({
    gateway: null,
  });
  const [locateReaderStatus, setLocateReaderStatus] = useState<string | null>(
    null
  );

  const [locatedreader, setLocatedreader] = useState<string>();
  const [discoveryLoading, setDiscoveryLoading] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<ITableData[]>([]);
  // const [readersData, setReadersData] = useState<any>();
  const { selectedCustomerInfo, selectedGateway } = useSelector(loginInfoData);
  const customerId = selectedCustomerInfo?.customerId?.toString();
  const { dicoveredReaders } = useSelector(discoveredReadersData);
  const [readersData, setReadersData] = useState<any>(null);
  const [timestamp, setTimestamp] = useState("");
  const [totalPages, setTotalPages] = useState<number>(1);
  const recordsRef = useRef<IRecordsRef>({ totalRecords: 0, devices: [] });
  const initialRef = useRef<boolean>(true);
  const pageSize = 5;

  useEffect(()=>{
    if(selectedReadersForApplyConfig && selectedReadersForApplyConfig.length>=1){
      setSelectedReaders(selectedReadersForApplyConfig)
      setView("review")
    } 
    else {
      // if no reader is selected enable gateway dropdown
      setView("reader")
      setSelectedReaders([])
    }
  },[selectedReadersForApplyConfig])

  const resetApplyConfigFromList = () => {
    setView("reader")
    dispatch(setSideNavActiveKey(null))
    dispatch(setPageFrom("list"));
    setSelectedReaders([])
    dispatch(resetSelectedReadersForApplyConfig())
    onClose && onClose()
  }

  useEffect(() => {
    const readersDataFromStore =
      (dicoveredReaders &&
        dropdownData.gateway?.value &&
        dicoveredReaders[dropdownData.gateway.value]?.readers) ||
      null;
    setReadersData(readersDataFromStore);
    const timestamp =
      (dicoveredReaders &&
        dropdownData.gateway?.value &&
        dicoveredReaders[dropdownData.gateway.value]?.timestamp) ||
      "";
    setTimestamp(timestamp);
  }, [dicoveredReaders, dropdownData.gateway]);
  useEffect(() => {
    return () => {
      setSelectedReaders([]);
      setView("reader");
      setDropdownData({
        gateway: null,
      });
      setSelectedRow([]);
    };
  }, []);

  const getGateqayDeviceData = (data: IDevices[]) => {
    return data.map((device: IDevices) => {
      return { label: device.name, value: device.deviceId };
    });
  };

  const onGatewaySelect = async (data: any) => {
    try {
      setDropdownData({
        gateway: data,
      });

      const payload = {
        gatewayId: data.value,
      };
      topic && topic.unsubscribe();
      setDiscoveryLoading(false);
      const readersDataFromStore =
        (dicoveredReaders && dicoveredReaders[data.value]?.readers) || null;
      setReadersData(readersDataFromStore);
      const timestamp =
        (dicoveredReaders && dicoveredReaders[data.value]?.timestamp) || "";
      setTimestamp(timestamp);
      if (!readersDataFromStore) {
        await discoverReaderService(payload, customerId);
        connectToStomp((stompClient: any) => {
          setDiscoveryLoading(true);

          const discoveryTimeout = setTimeout(()=>{
            topic?.unsubscribe();
            setDiscoveryLoading(false)
          },180*1000)

          topic = stompClient.subscribe(
            `/topic/${customerId}/${data?.value}`,
            function (response: any) {
              if (response.body) {
                setDiscoveryLoading(false);
                clearTimeout(discoveryTimeout)
                const readersResponse = JSON.parse(response.body);
                if (
                  readersResponse.status ===
                  deviceDiscoveryEnum.DEVICE_DISCOVERY_FAILED
                ) {
                  dispatch(
                    setGlobalToasts([
                      buildNotification("Error", readersResponse?.error),
                    ])
                  );
                  if (readersResponse?.deviceDetails.length > 0) {
                    let res = JSON.parse(response.body).deviceDetails;
                    res.timestamp = Date.now();
                    dispatch(
                      setReaders({
                        data: {
                          readers: res,
                          timestamp: Date.now().toString(),
                        },
                        gateway: data.value,
                      })
                    );
                  }
                } else {
                  let res = JSON.parse(response.body).deviceDetails;
                  res.timestamp = Date.now();
                  dispatch(
                    setReaders({
                      data: { readers: res, timestamp: Date.now().toString() },
                      gateway: data.value,
                    })
                  );
                }
              }
            }
          );
        });
      }
    } catch (err: any) {
      setDiscoveryLoading(false)
      topic && topic?.unsubscribe()
      dispatch(
        resetReaders({
          data: { readers: null, timestamp: Date.now().toString() },
          gateway: data.value,
        })
      );
      if (err.response?.status === 409) {
        dispatch(
          setGlobalToasts([
            buildNotification("Error", t("READERS.NO_READERS_CONNECTED")),
          ])
        );
      }
    }
  };

  const fetchReaders = async () => {
    const data = dropdownData.gateway as any;
    try {
      const payload = {
        gatewayId: data.value,
      };
      topic?.unsubscribe();

      await discoverReaderService(payload, customerId);
      dispatch(
        resetReaders({
          data: { readers: null, timestamp: Date.now().toString() },
          gateway: data.value,
        })
      );
      setDiscoveryLoading(true);

      connectToStomp((stompClient: any) => {
      
        const discoveryTimeout = setTimeout(()=>{
          topic?.unsubscribe();
          setDiscoveryLoading(false)
        },180*1000)
      
        topic = stompClient.subscribe(
          `/topic/${customerId}/${data?.value}`,
          function (response: any) {
            if (response.body) {
              setDiscoveryLoading(false);
              clearTimeout(discoveryTimeout); // clear the timeout if a response is received

              const readersResponse = JSON.parse(response.body);
              if (
                readersResponse.status === deviceDiscoveryEnum.DEVICE_DISCOVERY_FAILED &&
                readersResponse?.deviceDetails?.length === 0
              ) {
                dispatch(
                  setGlobalToasts([
                    buildNotification(
                      "Error",
                      t("READERS.OPERATION_INTERRUPTED")
                    ),
                  ])
                );
                topic && topic.unsubscribe();
                return;
              }
              if (
                readersResponse.status ===
                deviceDiscoveryEnum.DEVICE_DISCOVERY_FAILED
              ) {
                dispatch(
                  setGlobalToasts([
                    buildNotification("Error", readersResponse?.error),
                  ])
                );
                if (readersResponse?.deviceDetails.length > 0) {
                  let res = JSON.parse(response.body).deviceDetails;
                  res.timestamp = Date.now();
                  dispatch(
                    setReaders({
                      data: {
                        readers: res,
                        timestamp: Date.now().toString(),
                      },
                      gateway: data.value,
                    })
                  );
                }
              } else {
                let res = JSON.parse(response.body).deviceDetails;
                res.timestamp = Date.now();
                dispatch(
                  setReaders({
                    data: { readers: res, timestamp: Date.now().toString() },
                    gateway: data.value,
                  })
                );
              }
            }
          }
        );
      });
    } catch (err: any) {
      setDiscoveryLoading(false);
      topic && topic?.unsubscribe();
      dispatch(
        resetReaders({
          data: { readers: null, timestamp: Date.now().toString() },
          gateway: data.value,
        })
      );
      //unnecessary if check
      // if (err.response?.status === 409) {
      //   dispatch(
      //     setGlobalToasts([
      //       buildNotification("Error", t("READERS.NO_READERS_CONNECTED")),
      //     ])
      //   );
      // }
      if (err.response?.status === 409) {
        if (
          err?.response?.data?.statusDescription ===
          "Specified Action could not be initiated as the device is not connected with IoT"
        ) {
          dispatch(
            setGlobalToasts([
              buildNotification("Error", t("READERS.OPERATION_INTERRUPTED")),
            ])
          );
        } else {
          dispatch(
            setGlobalToasts([
              buildNotification("Error", t("READERS.NO_READERS_CONNECTED")),
            ])
          );
        }
      }
    }
  };

  const resetStatus = () => {
    setTimeout(() => {
      setLocateReaderStatus(null);
    }, 5000);
  };

  const locateReader = async (readerId: string) => {
    try {
      if (customerId) {
        const response = await locateReaderService(
          { gatewayId: dropdownData?.gateway?.value || "" },
          { connectedDeviceId: readerId },
          customerId
        );
        setLocatedreader(readerId);
        if (response.data.status.code === 200) {
          setLocateReaderStatus("success");
          resetStatus();
        } else {
          setLocateReaderStatus("failed");
          resetStatus();
          dispatch(
            setGlobalToasts([
              buildNotification("Error", response.data.status.message),
            ])
          );
        }
      }
    } catch (error) {
      setLocateReaderStatus("failed");
      resetStatus();
      dispatch(
        setGlobalToasts([
          buildNotification("Error", t("READERS.UNABLE_TO_LOCATE")),
        ])
      );
    }
  };
  const getLocateIconClass = (id: string) => {
    if (id === locatedreader) {
      return locateReaderStatus === "success"
        ? "success-color"
        : locateReaderStatus === "failed"
        ? "filure-color"
        : "default-color";
    } else {
      return "default-color";
    }
  };
  const renderReaderName = (_: ITableData, rowData: ITableData) => {
    return isVentoReader(rowData?.readerType || "") ? (
      <Tooltip
        message={
          "Configuration changes are restricted for Vento readers"
        }
        placement={TooltipPlacements.Top}
      >
        <Link disabled onClick={() => handleActionClick(rowData)}>
          {rowData?.id}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip
      message={ (!!rowData.operationAllowed && rowData && rowData?.restrictionReason && rowData?.restrictionReason?.length>=1) ? rowData && rowData?.restrictionReason :t("READERS.INSPECT_READER")}
      placement={TooltipPlacements.Top}
    >
      <Link
        className="text-left"
        disabled={ (!!rowData.operationAllowed && rowData && rowData?.restrictionReason && rowData?.restrictionReason?.length>=1) || rowData.fwUpdateRequired}
        onClick={() =>
          handleActionClick(rowData)
        }
      >
        {rowData?.id}
      </Link>
    </Tooltip>
      // <Link onClick={() => handleActionClick(rowData)}>{rowData.id}</Link>
    );
  };
  const renderFirmware = (_: ITableData, rowData: ITableData) => {
    if (rowData?.fwUpdateRequired) {
      return (
        <Tooltip
          message={t("READERS.FW_UPDATE_REQUIRED")}
          placement={TooltipPlacements.Top}
        >
          <div style={{ color: "#F8971D" }}>
            <span>{rowData?.fwDisplayName}</span>
            <Iconography icon="circleExclamation" />
          </div>
        </Tooltip>
      );
    } else {
      return <span>{rowData?.fwDisplayName}</span>;
    }
  };
  const renderConnectionType = (_: ITableData, rowData: ITableData) => {
    return (
      <div>
        {rowData && rowData.port && !isUSBReader(rowData?.port)
          ? t("READERS.HID_CONTROLLERS")
          : t("READERS.USB")}
      </div>
    );
  };
  const columnsData = [
    {
      dataField: "id",
      headerLabel: t("READERS.NAME"),
      component: "renderReaderName",
      cellSize: 2,
      type: "custom",
      isExpandable: true,
    },
    {
      dataField: "action",
      headerLabel: "Actions",
      clickHandler: "handleActionClick",
      cellSize: 1,
      type: "custom",
      component: "renderCustomCell",
      isExpandable: true,
    },

    {
      dataField: "dcid",
      headerLabel: t("READERS.CONFIGURATION_ID"),
      cellSize: 2,
      type: "text",
      isExpandable: true,
    },
    {
      dataField: "fwDisplayName",
      headerLabel: t("READERS.FIRMWARE"),
      cellSize: 1,
      type: "custom",
      component: "renderFirmware",
      isExpandable: true,
    },
    {
      dataField: "port",
      headerLabel: t("READERS.CONNECTION_TYPE"),
      cellSize: 1,
      type: "custom",
      component: "renderConnectionType",
      isExpandable: true,
    }
  ];
  const handleActionClick = (rowData: ITableData) => {
    setSelectedRow([rowData]);
  };

  const renderCustomCell = (_: ITableData, rowData: ITableData) => {
    return (
      <Iconography
        icon="volume"
        className={`cursor-pointer ${getLocateIconClass(rowData.id)}`}
        onClick={() => locateReader(rowData.id)}
        size={IconographySizes.Medium}
      />
    );
  };
  const onSelectReaders = (data: IReadersData[]) => {
    setSelectedReaders(data);
    setView("review");
  };
  const renderEmptyScreen = () => {
    return (
      <div className="hid-text-center hid-layout__mt-08 mb-15">
        <img
          src={Device}
          className="hid-origo__linq-reader-image"
          alt=""
          data-testid="reader-img"
          width={70}
        />
        {discoveryLoading ? (
          <>
            <Loader />
            <Typography variant={TypographyVariantEnum.TextSmall}>
              {t("READERS.CONNECTING_TO_READERS")}
            </Typography>
          </>
        ) : (
          <Typography
            variant={TypographyVariantEnum.BodyShortMarketing}
            className="hid-spacing__mt-04"
          >
            {t("READERS.SELECT_SYSTEM_SITE_GATEWAY")}
          </Typography>
        )}
      </div>
    );
  };
  const handleApplyTemplateConfirm = (
    invalidReaders: {
      deviceId: string;
      version: string;
    }[]
  ) => {
    const gateway = dropdownData?.gateway?.value || selectedGateway;
    if (gateway) {
      const data = {
        gateway: gateway,
      };
      const updatedReaderList = selectedReaders.filter(
        (r) => !invalidReaders.some((elem) => elem.deviceId === r.id)
      );
      onApplyTemplateConfirm && onApplyTemplateConfirm(data, updatedReaderList,true,true,resetApplyConfigFromList);
    }
  };
  const loadOptions = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ): Promise<any> => {
    const response: any = await getGatewayDeviceService(
      customerId,
      page,
      5,
      searchQuery
    );
    if (response) {
      const hasMore = page >= response?.data?.totalPages ? false : true;
      return {
        options: getGateqayDeviceData(
          response?.data?.genericDevicesPayload?.devices
        ),
        hasMore: hasMore,
        additional: {
          page: page + 1,
        },
      };
    }

    return {};
  };
  // const loadOptions = async (
  //   searchQuery: string,
  //   loadedOptions: any,
  //   { page }: any
  // ): Promise<any> => {
  //    if(searchQuery ){
  //     if(initialRef.current) {
  //      recordsRef.current = {totalRecords: 0, devices: []};
  //      initialRef.current = false;
  //     } else if(!initialRef.current) {
  //      recordsRef.current = {...recordsRef.current, devices: [...recordsRef.current.devices]};
  //      initialRef.current = true;
  //     }
  //    };

  //    const response: any = page <= totalPages && (recordsRef.current.totalRecords ? recordsRef.current.devices.length < recordsRef.current.totalRecords : true) ? await getGatewayDeviceService(
  //     customerId,
  //     page,
  //     pageSize,
  //     searchQuery
  //   ) : null;
  //   if (response) {
  //     const obj = {
  //       options: getGateqayDeviceData(
  //         response?.data?.genericDevicesPayload?.devices
  //       ),
  //       hasMore:
  //         page <= totalPages && recordsRef.current?.totalRecords < response?.data?.totalRecords,
  //       additional: {
  //         page: searchQuery ? 2 : page + 1,
  //       },
  //     };
  //     setTotalPages(response?.data?.totalPages);
  //         recordsRef.current = {totalRecords: response?.data?.totalRecords, devices:  [...recordsRef.current.devices, ...response?.data?.genericDevicesPayload?.devices]};
  //     return obj;
  //   }
  //   return {options: []};
  // };
  const dependencies = {
    handleActionClick: handleActionClick,
    renderCustomCell: renderCustomCell,
    renderReaderName: renderReaderName,
    renderFirmware: renderFirmware,
    renderConnectionType: renderConnectionType
  };
  return isOpen ? (
    <div className="reader-select-modal">
      <Modal
        size={ModalVariants.Large}
        onClose={ onClose }
        className="reader-select-modal"
      >
        {view === "reader" ? (
          <>
            <div className="hid-flex hid-flex-jc-center hid-spacing__mb-10">
              <Typography
                variant={TypographyVariantEnum.H1}
                className={"hid-spacing__mr-2"}
              >
                {t("READERS.SELECT_READERS")}
              </Typography>
            </div>
            <div className="hid-flex hid-flex-jc-center">
              <div className="w-30">
                <AsyncPaginate
                  key={"list-readers"}
                  value={dropdownData?.gateway}
                  loadOptions={loadOptions}
                  debounceTimeout={100}
                  onChange={(data: any) => onGatewaySelect(data)}
                  isSearchable={true}
                  placeholder={t("READERS.SELECT_GATEWAY")}
                  additional={{
                    page: 1,
                  }}
                />
              </div>
            </div>
            <div>
              {readersData ? (
                <ReadersTable
                  dependencies={dependencies}
                  data={readersData}
                  columnsData={columnsData}
                  onSelectReaders={onSelectReaders}
                  selectedreaders={selectedReaders}
                  timestamp={timestamp}
                  fetchReaders={fetchReaders}
                  isReaderSelectedModalReaderChanged={dropdownData?.gateway?.value || ""}
                />
              ) : (
                renderEmptyScreen()
              )}
            </div>
          </>
        ) : view === "review" ? (
          <div>
            <ReviewApplyDCID
              dcid={selectedDCID}
              back={selectedReadersForApplyConfig.length === 0 ?  () => setView("reader") : onClose ? onClose : ()=>setView("reader")}
              readers={selectedReaders}
              onApplyConfiguration={handleApplyTemplateConfirm}
            />
          </div>
        ) : null}
      </Modal>
    </div>
  ) : null;
}
